
.container-fluid {
 

    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: 90vw;
    left: calc(-1 * (100vw - 100%) / 2);
    max-width: 1000px;
    margin: 0 auto;
    text-align: center; /* Agregado para centrar los botones */
  
   
    padding: 20px;
    
  }
  .table-responsive {
    overflow-x: auto;
  }
  
  h1 {
    font-size: 32px;
    margin-top: 0;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  @media screen and (max-width: 767px) {
    .container-fluid.table {
      display: block;
      overflow-x: auto;
      width: 100%; /* Agregado para que la tabla cubra todo el ancho */
    }
  
    th,
    td {
      white-space: nowrap;
      text-align: center;
    }
  
    thead {
      display: none;
    }
  
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      border: 1px solid #ddd;
    }
  
    tbody td:before {
      font-weight: bold;
      margin-right: 5px;
      content: attr(data-label);
    }
  }
  button {
    background-color: #288b85;
    border-radius: 4px;
    border: 0;
    box-shadow: rgba(1,60,136,.5) 0 -1px 3px 0 inset,rgba(0,44,97,.1) 0 3px 6px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    
    font-family: "Space Grotesk",-apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    min-height: 30px;
    min-width: 120px;
    padding: 16px 20px 16px 20px;
    position: center;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    transition: all .2s cubic-bezier(.22, .61, .36, 1);
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    margin-top: 15px;
    
  }
  
  button:hover {
    background-color: #186b66;
    border: none;
  }
  form {
    max-width: 90%;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  h2 {
    text-align: center;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    border: 1px solid black;
    width: 80%;
    padding: .5rem;
    min-height: 2rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    
  }
  input[type="number"] {
    width: 80%;
    padding: 10px 10px 10px 10px;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  .container-form {
    display:inline-block;
    justify-content: center;
    align-items: center;
    
   
  }
  .button-66[type="submit"] {
    background-color: #25a888;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  .containerForm {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mb-4 {
    margin-bottom: 4rem;
  }
  
  .containerForm {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input, textarea {
    border: 1px solid black;
    width: 80%;
    padding: .5rem;
    min-height: 2rem;
  }
  
  button[type="submit"] {
    background-color: #237973;
    border-radius: 4px;
    border: 0;
    box-shadow: rgba(1,60,136,.5) 0 -1px 3px 0 inset,rgba(0,44,97,.1) 0 3px 6px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    
    font-family: "Space Grotesk",-apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    min-height: 30px;
    min-width: 120px;
    padding: 16px 20px 16px 20px;
    position: center;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    transition: all .2s cubic-bezier(.22, .61, .36, 1);
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    cursor: pointer;
    margin-top: 15px;
    
  }
  
  button[type="submit"]:hover {
    background-color: #0062cc;
  }
  
  @media (max-width: 768px) {
    .container-form {
      max-width: 100%;
      border: none;
      padding: 0;
    }
  
    input, textarea {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    input[type="text"]{
      width: 80%;
    }
    input[type="number"] {
      width: 80%;
    }

  }



  

